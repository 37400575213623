<template>
    <div>
        <GenericCrud
            route-name="SettingsPersonalData"
            route-action="list"
            :navbar-title="$t('settings.personalData.buttonLabel')"
            :navbar-back-route-without-history="{ name: 'Settings' }"
            :main-list-filter-label="$t('settings.personalData.filterLabel')"
            main-fist-scroll-to-selected
            :storeData="{
                mainListItems: {
                    action: 'personalData/fetch',
                    payload: { force: true },
                    options: null,
                    itemText: 'title'
                },
                mainItemById: {
                    action: 'personalData/fetchById',
                    payload: null,
                    options: null,
                },
                edit: {
                    action: 'personalData/edit',
                    payload: null,
                    options: null,
                    refetch: true,
                    hideButton: true
                },
                add: {
                    action: 'personalData/create',
                    payload: null,
                    options: null
                },
                delete: {
                    action: 'personalData/delete',
                    payload: null,
                    options: null,
                    hideButton: true
                },
                findUsage: {
                    action: 'personalData/findUsage',
                    payload: null,
                    options: null,
                    hideButton: true
                },
                replace: {
                    action: 'personalData/replaceItemInRecord',
                    options: null,
                    hideButton: true
                },
            }"
            :newItemModelFields="{
                confLevel: null,
                clientId: null,
                locale: currentLanguage,
                translations: defaultTranslations
            }"
        >
            <template v-slot:editorMask="x">
                <EditorMask
                    v-model="x.selectedMainItem"
                    :key="x.id"
                    :available-languages="availableLanguages"
                    :current-language="currentLanguage"
                    :isWaiting="x.isWaiting"
                    :isModified="x.isModified"
                    @modified="x.onSetModified"
                    @onSaveItem="x.onSaveItem"
                    @onDeleteItem="x.onDeleteItem"
                    @onFindUsage="x.onFindUsage"
                    @onCreateItem="x.onCreateItem"
                />
            </template>
        </GenericCrud>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GenericCrud from '@/components/generic/GenericCrud.vue';
import EditorMask from './EditorMask.vue';

export default {
    name: 'SettingsPersonalDataIndex',
    components: {
        GenericCrud,
        EditorMask,
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            availableLanguages: 'languageSettings/getAvailableLanguages',
            currentLanguage: 'languageSettings/getCurrentLanguage',
        }),
        defaultTranslations() {
            let translations = this.availableLanguages.map(x => {
                return { title: '', locale: x};
            });
            
            return translations.sort((a, b) => {
                return (b.locale === this.currentLanguage && a.locale !== this.currentLanguage) ? 1 : -1;
            });
        }
    }
};
</script>
