<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-3">
        <v-row class="justify-space-arround">
            <v-col cols="12" xl="10">
                <v-card class="default" outlined height="100%">
                    <EditorToolbar
                        :headline="model.translations.find(x => x.locale === currentLanguage).title"
                        :isModified="isModified"
                        :isWaiting="isWaiting"
                        :editMode="editMode"
                        @onSaveItem="$emit('onSaveItem')"
                        @onDeleteItem="$emit('onDeleteItem')"
                        @onCreateItem="$emit('onCreateItem')"
                        @onFindUsage="$emit('onFindUsage')"
                        :buttons="['new', 'save', 'delete', 'usageAndReplacement', 'translationState']"
                        />
                    <v-card-text class="pa-0">
                        <LeaTranslationTableInput v-model="model.translations" required autofocus @modified="$emit('modified', $event)" fieldValue="title" :fieldText="'label'" fieldType="text" />
                    </v-card-text>
                    <v-divider />
                    <v-card-text>
                        <v-col cols="12" md="8" lg="7" xl="6">
                            <v-slider
                                v-model="confLevel"
                                :label="$t('confLevel.title')"
                                :hint="confLevelHint"
                                persistent-hint
                                :max="6"
                                step="1"
                                ticks="always"
                                tick-size="2"
                                class="pb-3"
                                @input="$emit('modified', $event)"
                        ></v-slider>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import EditorToolbar from '../EditorToolbar.vue';
import LeaTranslationTableInput from '@/components/Input/LeaTranslationTableInput';

export default {
    name: 'SettingsPersonalDataEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        EditorToolbar,
        LeaTranslationTableInput
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        availableLanguages: {
            type: Array,
            default: () => []
        },
        currentLanguage: {
            type: String,
            default: null
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isModified: {
            type: Boolean,
            default: false
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
        };
    },
    mounted() {
        if (isUUID(this.model.id)) {
            this.model.clientId = this.value.id;
        }
    },
    computed: {
        editMode() {
            return !isUUID(this.model.id);
        },
        confLevel: {
            get() {
                if (this.model.confLevel === 9) {
                    return 6;
                }
                return this.model.confLevel;
            },
            set(val) {
                if (val === 6) {
                    this.model.confLevel = 9;
                } else {
                    this.model.confLevel = val;
                }
            },
        },
        confLevelHint() {
            if (this.model.confLevel === 0) {
                return this.$t('confLevel.undefinedProtectionRequirement');
            }
            if (this.model.confLevel === 1) {
                return this.$t('confLevel.publicProtectionRequirement');
            }
            if (this.model.confLevel === 2) {
                return this.$t('confLevel.lowProtectionRequirement');
            }
            if (this.model.confLevel === 3) {
                return this.$t('confLevel.mediumProtectionRequirement');
            }
            if (this.model.confLevel === 4) {
                return this.$t('confLevel.highProtectionRequirement');
            }
            if (this.model.confLevel === 5) {
                return this.$t('confLevel.veryHighProtectionRequirement');
            }
            if (this.model.confLevel === 9) {
                return this.$t('confLevel.article9ProtectionRequirement');
            }
            return this.$t('confLevel.undefinedProtectionRequirement');
        },
    },
};
</script>
